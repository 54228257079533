import { useEffect } from 'react'


let networkIs = "eth"

export function CheckNetwork() {
    const _network = window.localStorage.getItem("networkIsLocal");
    const _id = window.localStorage.getItem("selectedIdNet");
    networkIs = _network;
    return _id;
}

const eth = {
    name: 'eth',
    coin: 'ETH',
    apolloLink: 'https://api.thegraph.com/subgraphs/name/azurswap/azurswap-eth-v2',
    blockClientLink: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
    trustAsset: 'ethereum',
    graphName: "azurswap/azurswap-eth-v2",
    Wrap_coin: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase(),
    daiToken: ''.toLowerCase(),
    usdtToken: ''.toLowerCase(),
    usdcToken: ''.toLowerCase(),
    daiPair: ''.toLowerCase(),
    usdtPair: ''.toLowerCase(),
    usdcPair: ''.toLowerCase(),
    showTransaction: 'https://etherscan.io/tx/',
    showAddress: 'https://www.etherscan.io/address/',
    showToken: 'https://www.etherscan.io/token/',
    showBlock: 'https://etherscan.io/block/',
}

const bsc = {
    name: 'bsc',
    coin: 'BNB',
    apolloLink: 'https://api.thegraph.com/subgraphs/name/azurswap/azurswap-bsc-v2',
    blockClientLink: 'https://api.thegraph.com/subgraphs/name/matthewlilley/bsc-blocks',
    trustAsset: 'smartchain',
    graphName: "azurswap/azurswap-bsc-v2",
    Wrap_coin: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'.toLowerCase(),
    daiToken: ''.toLowerCase(),
    usdtToken: ''.toLowerCase(),
    usdcToken: ''.toLowerCase(),
    daiPair: ''.toLowerCase(),
    usdtPair: ''.toLowerCase(),
    usdcPair: ''.toLowerCase(),
    showTransaction: 'https://bscscan.com/tx/',
    showAddress: 'https://www.bscscan.com/address/',
    showToken: 'https://www.bscscan.com/token/',
    showBlock: 'https://bscscan.com/block/',
}

const avax = {
    name: 'avax',
    coin: 'AVAX',
    apolloLink: 'https://api.thegraph.com/subgraphs/name/azurswap/azurswap-avalanche-v2',
    blockClientLink: 'https://api.thegraph.com/subgraphs/name/dasconnor/avalanche-blocks',
    trustAsset: 'avalanchec',
    graphName: "azurswap/azurswap-avalanche-v2",
    Wrap_coin: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'.toLowerCase(),
    daiToken: ''.toLowerCase(),
    usdtToken: ''.toLowerCase(),
    usdcToken: ''.toLowerCase(),
    daiPair: ''.toLowerCase(),
    usdtPair: ''.toLowerCase(),
    usdcPair: ''.toLowerCase(),
    showTransaction: 'https://snowtrace.io/tx/',
    showAddress: 'https://www.snowtrace.io/address/',
    showToken: 'https://www.snowtrace.io/token/',
    showBlock: 'https://snowtrace.io/block/',
}

const matic = {
    name: 'matic',
    coin: 'MATIC',
    apolloLink: 'https://api.thegraph.com/subgraphs/name/azurswap/azurswap-polygon-v2',
    blockClientLink: 'https://api.thegraph.com/subgraphs/name/ord786/matic-blocks',
    trustAsset: 'polygon',
    graphName: "azurswap/azurswap-polygon-v2",
    Wrap_coin: '0x0000000000000000000000000000000000001010'.toLowerCase(),
    daiToken: ''.toLowerCase(),
    usdtToken: ''.toLowerCase(),
    usdcToken: ''.toLowerCase(),
    daiPair: ''.toLowerCase(),
    usdtPair: ''.toLowerCase(),
    usdcPair: ''.toLowerCase(),
    showTransaction: 'https://polygonscan.com/tx/',
    showAddress: 'https://www.polygonscan.com/address/',
    showToken: 'https://www.polygonscan.com/token/',
    showBlock: 'https://polygonscan.com/block/',
}

const celo = {
    name: 'celo',
    coin: 'CELO',
    apolloLink: 'https://api.thegraph.com/subgraphs/name/sajadkoroush/azurswap-eth-v2',
    blockClientLink: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks',
    trustAsset: 'celo',
    graphName: "sajadkoroush/azurswap-eth-v2",
    Wrap_coin: ''.toLowerCase(),
    daiToken: ''.toLowerCase(),
    usdtToken: ''.toLowerCase(),
    usdcToken: ''.toLowerCase(),
    daiPair: ''.toLowerCase(),
    usdtPair: ''.toLowerCase(),
    usdcPair: ''.toLowerCase(),
    showTransaction: 'https://etherscan.io/tx/',
    showAddress: 'https://www.etherscan.io/address/',
    showToken: 'https://www.etherscan.io/token/',
    showBlock: 'https://etherscan.io/block/',
}

const fantom = {
    name: 'fantom',
    coin: 'FANTOM',
    apolloLink: 'https://api.thegraph.com/subgraphs/name/azurswap/azurswap-fantom-v2',
    blockClientLink: 'https://api.thegraph.com/subgraphs/name/ducquangkstn/fantom-blocks',
    trustAsset: 'fantom',
    graphName: "azurswap/azurswap-fantom-v2",
    Wrap_coin: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'.toLowerCase(),
    daiToken: ''.toLowerCase(),
    usdtToken: ''.toLowerCase(),
    usdcToken: ''.toLowerCase(),
    daiPair: ''.toLowerCase(),
    usdtPair: ''.toLowerCase(),
    usdcPair: ''.toLowerCase(),
    showTransaction: 'https://FTMScan.com/tx/',
    showAddress: 'https://www.FTMScan.com/address/',
    showToken: 'https://www.FTMScan.com/token/',
    showBlock: 'https://FTMScan.com/block/',
}

let networks = {
    eth,
    bsc,
    avax,
    matic,
    fantom,
    celo,
}

export const NetworksList = [
    { value: 'eth', label: 'Ethereum', id: 0 },
    { value: 'bsc', label: 'BSC', id: 1 },
    { value: 'avax', label: 'AVAX', id: 2 },
    { value: 'matic', label: 'MATIC', id: 3 },
    { value: 'fantom', label: 'FANTOM', id: 4 },
    { value: 'celo', label: 'CELO', id: 5, isDisabled: true },
    { value: 'opmainnet', label: 'OP Mainnet', id: 6, isDisabled: true },
];

// export function NetState2() {
//     const [networkX, setNetX] = useState('eth')
//     networkIs = networkX
// }


export function NetState() {
    let netState;
    const _network = window.localStorage.getItem("networkIsLocal");
    networkIs = _network;

    switch (networkIs) {
        case "eth":
            netState = networks.eth;
            break;
        case "bsc":
            netState = networks.bsc;
            break;
        case "avax":
            netState = networks.avax;
            break;
        case "fantom":
            netState = networks.fantom;
            break;
        case "celo":
            netState = networks.celo;
            break;
        case "matic":
            netState = networks.matic;
            break;


        default:
            netState = networks.eth;
            break;
    }
    // if (netState.name !== networkIs) {
    //     // window.location.reload(false);
    // }

    return netState;
}


export function SetNetwork(_networkId) {

    useEffect(() => {
        if (_networkId !== null) {
            networkIs = _networkId;
            localStorage.setItem("networkIsLocal", networkIs);
        }
    });

    // window.location.reload(false);

}


