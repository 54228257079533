import React from 'react';
import Select, { components } from 'react-select';
import { NetworksList } from '../../hooks/Networks';
import { CheckNetwork } from '../../hooks/Networks';
import logo from '../../assets/logo_white.png';
import { transparentize } from 'polished'


// interface WrapperProps {
//   open: boolean;
//   color: string;
//   theme: {
//     panelColor: string;
//   };
// }



let idNet = CheckNetwork();

function ChangeNetworkOnList(_value, _id) {
  window.localStorage.setItem("networkIsLocal", _value);
  window.localStorage.setItem("selectedIdNet", _id);
  if (parseInt(idNet) !== _id) {
    window.location.reload();
  }
}


const Logo = () => {
  return (
    <img src={logo} alt="Logo" style={{ width: '30px', height: '30px' }} />
  );
};

const DropdownButton = () => {
  // const [networkSelected, setnetworkSelected] = useState(NetworksList[0]);

  const handleChange = (selectedOption) => {
    // setnetworkSelected(selectedOption);
    // console.log('networkSelected:', networkSelected);
    ChangeNetworkOnList(selectedOption.value, selectedOption.id);
  };

  // Custom component to render the logo
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Logo />
      </components.DropdownIndicator>
    );
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: `${({ theme, small, open }) => (small ? (open ? theme.bg6 : 'none') : transparentize(0.4, theme.bg6))}`,

      padding: `2px 5px`,
      borderRadius: `12px`,

      color: `${({ theme }) => theme.text1}`,
      fontSize: `${({ large }) => (large ? '20px' : '14px')}`,


    }),
    menu: (provided) => ({
      ...provided,
      zIndex: `9999999`,
      background: `${({ theme }) => theme.bg6}`,
      boxShadow: `${({ theme }) => theme.shadowColor}`,
      color: `${({ theme }) => theme.text1}`,
    }),
  };

  return (
    <>
      <Select
        styles={customStyles}
        className="basic-single"
        classNamePrefix="select"
        value={NetworksList[idNet]}
        isSearchable={true}
        name="Networks"
        options={NetworksList}
        components={{ DropdownIndicator }}
        onChange={handleChange}
      />
      <div></div>
    </>
  );
};

export default DropdownButton;
